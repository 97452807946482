

































































































































































































































































































.custom-select {
  width: 150px;
  height: calc( 1.5em + 0.5rem + 2px );
  padding: 0.25rem 0.5rem;
  font-size: 0.765625rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  min-width: 200px;
}
